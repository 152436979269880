// Generated by Framer (92b2253)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import SocialIcon from "./gVYX5wVhP";
const SocialIconFonts = getFonts(SocialIcon);
const SocialIconControls = getPropertyControls(SocialIcon);

const enabledGestures = {AB0pHrsDz: {hover: true}};

const cycleOrder = ["AB0pHrsDz"];

const serializationHash = "framer-PG8Ke"

const variantClassNames = {AB0pHrsDz: "framer-v-z6792u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {Facebook: "WOiEPwbLM", Instagram: "FJeLTc66b", Linkedin: "Tc4ZFeg2e", Snapchat: "i2SdmBsJA", TikTok: "U_LuNQsaG", Twitter: "Nk8_ITYCc", Youtube: "HeOkAXK3o"}

const getProps = ({changeIcon, height, id, socialLink, width, ...props}) => { return {...props, LtwH7Lljn: socialLink ?? props.LtwH7Lljn, TbdAtpy38: humanReadableEnumMap[changeIcon] ?? changeIcon ?? props.TbdAtpy38 ?? "FJeLTc66b"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;changeIcon?: keyof typeof humanReadableEnumMap;socialLink?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TbdAtpy38, LtwH7Lljn, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "AB0pHrsDz", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={LtwH7Lljn} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-z6792u", className, classNames)} framer-6xflwl`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"AB0pHrsDz"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-4ca3a15b-7aca-4326-8b0b-4d2993ab51e0, rgb(25, 25, 25))", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, boxShadow: "inset 0px 1px 2px 0px rgba(255, 255, 255, 0.1)", ...style}} variants={{"AB0pHrsDz-hover": {boxShadow: "inset 0px 1px 2px 0px var(--token-009407b2-20b2-426c-8de3-c887af3ea436, rgb(128, 128, 128)) /* {\"name\":\"grey-50\"} */"}}} {...addPropertyOverrides({"AB0pHrsDz-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider width={"40px"} {...addPropertyOverrides({"AB0pHrsDz-hover": {width: "48px"}}, baseVariant, gestureVariant)}><motion.div className={"framer-75zses-container"} layoutDependency={layoutDependency} layoutId={"IjJejMtoV-container"}><SocialIcon height={"100%"} id={"IjJejMtoV"} layoutId={"IjJejMtoV"} style={{height: "100%", width: "100%"}} variant={TbdAtpy38} width={"100%"}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PG8Ke.framer-6xflwl, .framer-PG8Ke .framer-6xflwl { display: block; }", ".framer-PG8Ke.framer-z6792u { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 32px; height: 104px; justify-content: center; overflow: visible; padding: 32px 0px 32px 0px; position: relative; text-decoration: none; width: 224px; }", ".framer-PG8Ke .framer-75zses-container { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 40px); position: relative; width: 40px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-PG8Ke.framer-z6792u { gap: 0px; } .framer-PG8Ke.framer-z6792u > * { margin: 0px; margin-left: calc(32px / 2); margin-right: calc(32px / 2); } .framer-PG8Ke.framer-z6792u > :first-child { margin-left: 0px; } .framer-PG8Ke.framer-z6792u > :last-child { margin-right: 0px; } }", ".framer-PG8Ke.framer-v-z6792u.hover .framer-75zses-container { height: var(--framer-aspect-ratio-supported, 48px); width: 48px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 104
 * @framerIntrinsicWidth 224
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wc6HIvCg4":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TbdAtpy38":"changeIcon","LtwH7Lljn":"socialLink"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDCyLGNaRQ: React.ComponentType<Props> = withCSS(Component, css, "framer-PG8Ke") as typeof Component;
export default FramerDCyLGNaRQ;

FramerDCyLGNaRQ.displayName = "Social block";

FramerDCyLGNaRQ.defaultProps = {height: 104, width: 224};

addPropertyControls(FramerDCyLGNaRQ, {TbdAtpy38: SocialIconControls?.["variant"] && {...SocialIconControls["variant"], defaultValue: "FJeLTc66b", description: undefined, hidden: undefined, title: "Change Icon"}, LtwH7Lljn: {title: "Social Link", type: ControlType.Link}} as any)

addFonts(FramerDCyLGNaRQ, [{explicitInter: true, fonts: []}, ...SocialIconFonts], {supportsExplicitInterCodegen: true})